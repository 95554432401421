/* eslint-disable no-case-declarations */
import { format, parse } from 'date-fns';
import { BuscaDadosImprimirProps } from '~/pages/PdvOnline/types/services';
import { moneyFormat, pontuaCpfCnpj } from '~/utils/functions';

class ImprimirCupomVenda {
  private receiptData: BuscaDadosImprimirProps;
  private header: string;

  constructor(receiptData: BuscaDadosImprimirProps) {
    this.receiptData = receiptData;
    this.header = this.createReceiptHeader();
  }

  private static centerText(text: string, lineLength: number): string {
    const spaces = ' '.repeat(Math.max(0, (lineLength - text.length) / 2));
    return spaces + text + spaces;
  }

  private static createGrid(
    totalLength: number,
    numCols: number,
    ...labels: string[]
  ): string {
    if (numCols <= 0)
      throw new Error('O número de colunas deve ser maior que zero.');

    if (labels.length !== numCols)
      throw new Error(
        'A quantidade de labels deve corresponder ao número de colunas.',
      );

    const totalLabelsLength = labels.reduce(
      (sum, label) => sum + label.length,
      0,
    );
    const spaceBetweenLabels = totalLength - totalLabelsLength;

    if (spaceBetweenLabels < numCols - 1) return labels.join(' ');

    const spacePerGap = Math.floor(spaceBetweenLabels / (numCols - 1));
    const remainingSpace = spaceBetweenLabels % (numCols - 1);

    return labels
      .map((label, index) => {
        const padding =
          index < labels.length - 1
            ? ' '.repeat(spacePerGap + (index < remainingSpace ? 1 : 0))
            : '';
        return ImprimirCupomVenda.padString(label, 'left', 2) + padding;
      })
      .join('');
  }

  private static formatCep(cep: string): string {
    return `${cep.slice(0, 5)}-${cep.slice(5)}`;
  }

  private static createHyphens(
    spaceCount: number,
    hyphenCount: number,
  ): string {
    const hyphens = '-'.repeat(hyphenCount);
    const spaces = ' '.repeat((spaceCount - hyphenCount) / 2);
    return spaces + hyphens + spaces;
  }

  static wrapText(text: string, maxLength: number): string {
    let wrappedText = '';
    let start = 0;

    while (start < text.length) {
      const end = start + maxLength;
      wrappedText += `${text.substring(start, end)}\n`;
      start = end;
    }

    return wrappedText.trim();
  }

  private static padString(
    str: string,
    side: 'left' | 'right' | 'both',
    spaces: number,
  ): string {
    const padding = ' '.repeat(spaces);
    const lines = str.split('\n');

    const paddedLines = lines.map((line) => {
      switch (side) {
        case 'left':
          return padding + line;
        case 'right':
          return line + padding;
        case 'both':
          const leftPadding = ' '.repeat(spaces / 2);
          return leftPadding + line + leftPadding;
        default:
          throw new Error('Lado inválido. Use "left", "right" ou "both".');
      }
    });

    return paddedLines.join('\n');
  }

  private createReceiptHeader(): string {
    const { receiptData } = this;

    const razaoSocial = receiptData.des_razao_social.split(' - ')[1];
    const cnpj = `CNPJ: ${pontuaCpfCnpj(receiptData.num_cnpj)}`;
    const cepEuf = `${ImprimirCupomVenda.formatCep(receiptData.num_cep)} ${
      receiptData.des_cidade_uf_view
    }`;

    const lines = [
      receiptData.des_fantasia,
      razaoSocial,
      cnpj,
      receiptData.des_logradouro,
      receiptData.des_complemento || '',
      `BAIRRO: ${receiptData.des_bairro}`,
      cepEuf,
    ];

    const filteredLines = receiptData.des_complemento
      ? lines
      : lines.filter((line) => line);

    return filteredLines
      .map((line) => ImprimirCupomVenda.centerText(line, 45))
      .join('\n');
  }

  private static createTag(): string {
    const hyphens = ImprimirCupomVenda.createHyphens(45, 43);
    const centeredText = ImprimirCupomVenda.centerText('Documento NF', 45);
    return `\n\n${hyphens}\n${centeredText}\n${hyphens}\n`;
  }

  private static padString2(
    str: string,
    length: number,
    position: any,
  ): string {
    if (str.length > length) return str.slice(0, length);

    const padding = ' '.repeat(length - str.length);

    if (position === 'left') return str + padding;

    return padding + str;
  }

  private static createGridHeader(): string {
    const headerGridItems = [
      { line: 1, label: 'Descricao', size: 42, position: 'left' },
      { line: 2, label: 'Codigo', size: 8, position: 'left' },
      { line: 2, label: 'Qtd', size: 3, position: 'right' },
      { line: 2, label: 'UN', size: 3, position: 'left' },
      { line: 2, label: 'Vl.Unit', size: 8, position: 'right' },
      { line: 2, label: 'Vl.Desc', size: 7, position: 'right' },
      { line: 2, label: 'Vl.Tot', size: 8, position: 'right' },
    ];

    const line1Items = headerGridItems.filter((item) => item.line === 1);
    const line2Items = headerGridItems.filter((item) => item.line === 2);

    const line1 = line1Items
      .map((item) => this.padString2(item.label, item.size, item.position))
      .join('');

    const line2 = line2Items
      .map((item) => this.padString2(item.label, item.size, item.position))
      .join(' ');

    return ImprimirCupomVenda.padString(`${line1}\n${line2}`, 'left', 2);
  }

  private createGridItems(): string {
    const { des_json_itens } = this.receiptData;

    const itensKey = [
      { line: 1, key: 'des_produto', size: 42, position: 'left' },
      { line: 2, key: 'cod_produto', size: 8, position: 'left' },
      { line: 2, key: 'qtd_pedido', size: 3, position: 'right' },
      { line: 2, key: 'des_unidade', size: 3, position: 'left' },
      { line: 2, key: 'val_preco', size: 8, position: 'right' },
      { line: 2, key: 'val_desconto', size: 7, position: 'right' },
      { line: 2, key: 'val_total_item', size: 8, position: 'right' },
    ];

    const items = des_json_itens.map((item: any) => {
      const line1 = ImprimirCupomVenda.padString2(
        ImprimirCupomVenda.wrapText(item.des_produto, 42),
        42,
        'left',
      );

      const line2Parts = itensKey
        .filter((keyItem) => keyItem.line === 2)
        .map((keyItem) => {
          let value = item[keyItem.key as keyof BuscaDadosImprimirProps];
          if (keyItem.key === 'qtd_pedido') value = `${value}X`;

          const formattedValue = keyItem.key.startsWith('val_')
            ? moneyFormat(value.toString())
            : value.toString();

          return ImprimirCupomVenda.padString2(
            formattedValue,
            keyItem.size,
            keyItem.position,
          );
        })
        .join(' ');

      return `${line1}\n${line2Parts}`;
    });

    const formattedItems = items.join('\n');

    return ImprimirCupomVenda.padString(formattedItems, 'left', 2);
  }

  private generateGridItems(): string {
    const headerItems = ImprimirCupomVenda.createGridHeader();
    const items = this.createGridItems();
    const separator = ImprimirCupomVenda.createHyphens(45, 43);

    return `${headerItems}\n${separator}\n${items}\n${separator}\n`;
  }

  private static createTotalizador(label: string, value: string): string {
    const formattedString = ImprimirCupomVenda.createGrid(40, 2, label, value);

    if (label === 'Valor Total') return `${formattedString}\n\n\n`;
    return `${formattedString}\n`;
  }

  private createTotalizadores(): string {
    const { receiptData } = this;

    const totalUnidade = receiptData.des_json_itens.reduce(
      (acc, { qtd_pedido }) => acc + qtd_pedido,
      0,
    );

    const totalizadores = [
      ImprimirCupomVenda.createTotalizador(
        'Qtd. Total de Itens',
        `${receiptData.qtd_itens || 0} item(ns)`,
      ),
      ImprimirCupomVenda.createTotalizador(
        'Qtd. Total de Un',
        totalUnidade.toString() || '0',
      ),
      ImprimirCupomVenda.createTotalizador(
        'Valor Total',
        moneyFormat(receiptData.val_total_pedido.toFixed(2) || '0'),
      ),
      ImprimirCupomVenda.createTotalizador(
        'Valor a Pagar',
        moneyFormat(receiptData.val_total_pedido.toFixed(2) || '0'),
      ),
    ];

    return `${totalizadores.join('')}${ImprimirCupomVenda.createHyphens(
      45,
      43,
    )}\n`;
  }

  private createFormasPagamento(): string {
    const { des_json_financeiro, num_pdv, num_pedido, val_total_pedido } =
      this.receiptData;

    const financeiro = (des_json_financeiro ?? [])
      .map(({ des_finalizadora, val_parcela }) => {
        const valParcelaFormatado = moneyFormat(val_parcela.toFixed(2));
        return ImprimirCupomVenda.createGrid(
          40,
          2,
          des_finalizadora,
          valParcelaFormatado,
        );
      })
      .join('\n');

    const totalParcelas = (des_json_financeiro ?? []).reduce(
      (acc, { val_parcela }) => acc + val_parcela,
      0,
    );
    const totalParcelasFormatado = moneyFormat(totalParcelas.toFixed(2));

    const totalPagamento = ImprimirCupomVenda.createGrid(
      40,
      2,
      'Total Pagamento',
      totalParcelasFormatado,
    );

    const trocoCalc = totalParcelas - val_total_pedido;
    const troco = ImprimirCupomVenda.createGrid(
      40,
      2,
      'Troco',
      moneyFormat(trocoCalc.toFixed(2)),
    );

    const observacaoCaixa = ImprimirCupomVenda.padString(
      `Observacao Caixa:${num_pdv} - Cupom:${num_pedido}`,
      'left',
      2,
    );

    const header = ImprimirCupomVenda.createGrid(40, 2, 'FORMA', 'VALOR PAGO');

    const hifen = ImprimirCupomVenda.createHyphens(45, 43);

    return `${header}\n${financeiro}\n${totalPagamento}\n${troco}\n\n${observacaoCaixa}\n${hifen}\n\n`;
  }

  private createInfoVenda(): string {
    const { receiptData } = this;

    const dtaEmissao = parse(
      receiptData.dta_emissao,
      "yyyy-MM-dd'T'HH:mm:ss.SSSX",
      new Date(),
    );

    return [
      `Venda n° ${receiptData.num_pedido}`,
      `Emissao: ${format(dtaEmissao, 'dd/MM/yyyy HH:mm')}`,
      `Usuario: ${receiptData.des_operador}`,
    ]
      .map((line) => ImprimirCupomVenda.centerText(line || '', 45))
      .join('\n');
  }

  private createClienteInfo(): string {
    const { receiptData } = this;

    const hifen = ImprimirCupomVenda.createHyphens(45, 43);
    const cliente = ImprimirCupomVenda.padString(
      ImprimirCupomVenda.wrapText(
        `CLIENTE: ${receiptData.des_cliente ?? ''}`,
        42,
      ),
      'left',
      2,
    );
    const cpfcnpj = ImprimirCupomVenda.padString(
      `CPF/CNPJ: ${pontuaCpfCnpj(receiptData.num_cpf_cnpj ?? '')}`,
      'left',
      2,
    );

    return `\n\n${hifen}\n${cliente}\n${cpfcnpj}\n${hifen}\n\n`;
  }

  private createInfoCaixaCupom(): string {
    const {
      receiptData: { num_pdv, num_pedido },
    } = this;

    return ImprimirCupomVenda.padString(
      `Caixa: ${num_pdv}  Cupom: ${num_pedido}`,
      'left',
      2,
    );
  }

  private generateReceipt(): string {
    const header = this.createReceiptHeader();
    const tagHeader = ImprimirCupomVenda.createTag();
    const gridItems = this.generateGridItems();
    const totalizadores = this.createTotalizadores();
    const formaPagamento = this.createFormasPagamento();
    const infoVenda = this.createInfoVenda();
    const infoCliente = this.createClienteInfo();
    const infoPdvCupom = this.createInfoCaixaCupom();

    return (
      header +
      tagHeader +
      gridItems +
      totalizadores +
      formaPagamento +
      infoVenda +
      infoCliente +
      infoPdvCupom
    );
  }

  public createCupomVenda(): string {
    const recibo = this.generateReceipt();
    return recibo;
  }
}

export default ImprimirCupomVenda;
